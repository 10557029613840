import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { LoginUtil, trackPageView } from 'common.f6st.com';
import { BasketPage } from './pages/BasketPage';
import { HomePage } from './pages/HomePage';
import { OrdersPage } from './pages/OrdersPage';
import { ProductDetailPage } from './pages/ProductDetailPage';
import { Typography } from '@mui/material';
import { CallerType, QrCodeLocationType } from 'base.f6st.com';
import { AppProvider } from './components/AppProvider';

function isQrCodeLocationType(value: any): value is QrCodeLocationType {
  return Object.values(QrCodeLocationType).includes(value);
}

export const App: React.FC = () => {
  const location = useLocation();
  const [qrCodeId, setQrCodeId] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [locationType, setLocationType] = useState<QrCodeLocationType | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const cParam = queryParams.get('c')?.trim();

    if (cParam && !qrCodeId && !customerId && !locationType) {
      const parts = cParam.split(':');
      if (parts.length === 3) {
        const qrCodeId = parts[0].trim();
        const customerId = parts[1].trim();
        const locationTypeString = parts[2].trim();

        if (isQrCodeLocationType(locationTypeString)) {
          setQrCodeId(qrCodeId);
          setCustomerId(customerId);
          setLocationType(locationTypeString);
        }
      }
    }
  }, [location, qrCodeId, customerId, locationType]);

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  if (!qrCodeId || !customerId || !locationType) {
    return <Typography variant="h1">Invalid QR code</Typography>;
  }

  return (
    <AppProvider qrCodeId={qrCodeId} customerId={customerId} locationType={locationType}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {LoginUtil.isAuthenticationToken(CallerType.ORDER) && (
          <>
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/basket" element={<BasketPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/product/:productId" element={<ProductDetailPage />} />
          </>
        )}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AppProvider>
  );
};
