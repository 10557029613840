import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { formatDistanceToNow } from "date-fns";
import { useTranslation } from "react-i18next";
import { enUS } from "date-fns/locale";
import { useAppContext } from "../components/AppProvider";
import { I18nUtil, Order, OrderUtil } from "base.f6st.com";
import { ReceiptLink } from "common.f6st.com";

interface OrderInfoDialogProps {
  open: boolean;
  order: Order;
  onClose: () => void;
}

export const OrderInfoDialog = ({ open, order, onClose }: OrderInfoDialogProps) => {
  const { t } = useTranslation();
  const customer = useAppContext().getCustomer();
  const { languageCodePrimary } = customer.businessSettings;
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const totalAmountString = OrderUtil.formatAmount(order.totalAmount,currency);

  if (!order) return null;

  const selectedLocale = enUS;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('Order ID')}: #{order.id}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Receipt Number')}</TableCell>
              <TableCell>{order.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Amount')}</TableCell>
              <TableCell>{totalAmountString}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Time')}</TableCell>
              <TableCell>
                {new Date(order.date).toLocaleString(languageCodePrimary)}<br />
                <Typography variant="body2" color="textSecondary">
                  {formatDistanceToNow(new Date(order.date), { addSuffix: true, locale: selectedLocale })}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Receipt')}</TableCell>
              <TableCell>
                <ReceiptLink order={order} customer={customer} businessReceipt={true} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};
