// Checkout.tsx

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { Container, Alert, Typography, Box, Card, CardContent, Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { loadStripe, PaymentIntentResult } from '@stripe/stripe-js';
import { useAppContext } from './AppProvider';
import { DiscountUtil } from './ComebackDiscountStorage';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { DeliveryOrderDetails, QrCodeLocationType, Log, AppError, PaymentStatus, OrderUtil, Order, I18nUtil } from 'base.f6st.com';
import { GeoLocationUtil, StripeUtil, AddressSelection, StandardButton, Config } from 'common.f6st.com';

// Helper function to get the first error message from an errors object
const getFirstError = (errors: any): string => {
  if (typeof errors !== 'object' || errors === null) return '';
  const errorValues = Object.values(errors);
  return errorValues.find(error => typeof error === 'string') || '';
};

interface CheckoutProps {
  onPaymentSuccess: (result: PaymentIntentResult, deliveryInfo?: DeliveryOrderDetails) => void;
  executePayment: (callback: () => void) => void;
  handleSubmitOrderClick: () => void;
}

interface CheckoutFormProps {
  clientSecret: string;
  onPaymentSuccess: (result: PaymentIntentResult, deliveryInfo?: DeliveryOrderDetails) => void;
  executePayment: (callback: () => void) => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ onPaymentSuccess, executePayment }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handlePay = useCallback(async () => {
    if (!stripe || !elements) {
      toast.error("Stripe has not been properly initialized.");
      return;
    }

    const result: PaymentIntentResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: Config.getPaymentRedirectUrl(),
      },
      redirect: "if_required",
    });

    if (result.error) {
      toast.error(result.error.message || "Payment failed");
    } else {
      onPaymentSuccess(result);
    }
  }, [stripe, elements, onPaymentSuccess]);

  useEffect(() => {
    executePayment(handlePay);
  }, [executePayment, handlePay]);

  return (
    <div>
      <PaymentElement options={{
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false
        }
      }} />
    </div>
  );
};

export const Checkout: React.FC<CheckoutProps> = ({ onPaymentSuccess, executePayment, handleSubmitOrderClick }) => {

  const customer = useAppContext().getCustomer();
  const qrCode = useAppContext().qrCode;
  const initPaymentOnce = useRef(true);
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [comebackMessage, setComebackMessage] = useState<string | null>(null);
  const [payInPerson, setPayInPerson] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(qrCode.locationType === QrCodeLocationType.ADDRESS ? 0 : 1);
  const DELIVERY_INFO_STORAGE_KEY = 'deliveryInfo-' + customer.id;
  const order: Order = useAppContext().basket.getOrder();
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const { totalString, serviceFee, deliveryFee, serviceFeeString, deliveryFeeString } = OrderUtil.getOrderAmount(order, currency);
  const deliverySettings = customer.businessSettings.delivery;

  const formik = useFormik({
    initialValues: {
      fullName: '',
      phoneNumber: '',
      deliveryInstructions: '',
      address: {
        placeId: '',
        zip: '',
        city: '',
        street: '',
        streetNumber: '',
        lat: 0,
        lng: 0,
      },
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Full Name is required'),
      phoneNumber: Yup.string().required('Phone Number is required'),
      deliveryInstructions: Yup.string(),
      address: Yup.object().shape({
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
        streetNumber: Yup.string().required("Street number is required"),
        zip: Yup.string().required("ZIP Code is required"),
      }),
    }),
    onSubmit: async (values) => {
      if (activeStep === 0) {

        if (deliverySettings) {
          Log.debug('Delivery settings found', deliverySettings);
          const { maxDistance, excludedZips, minimumOrderAmount, freeDeliveryAmountLimit, deliveryFee } = deliverySettings;

          if (qrCode.locationType !== QrCodeLocationType.ADDRESS) {
            Log.debug('QR code location type is not ADDRESS', qrCode.locationType);
            toast.error('This QR code cannot be used for delivery');
            return;
          }

          if (excludedZips?.includes(values.address.zip)) {
            Log.debug('Address zip is in excluded zips', values.address.zip);
            toast.error('Not possible to deliver to this address');
            return;
          }


          if (maxDistance > 0) {
            Log.debug('Max distance is greater than 0', maxDistance);
            const withinDistance = GeoLocationUtil.isWithinDistance(
              deliverySettings.maxDistanceAddress,
              values.address,
              maxDistance,
              I18nUtil.getDistanceMetricSystem(customer.businessSettings.countryCode)
            );
            Log.debug('Within distance result', withinDistance);

            if (!withinDistance) {
              toast.error('Not possible to deliver to this address');
              return;
            }
          }

          const orderAmount = OrderUtil.getOrderAmount(order, currency).total;
          Log.debug('Order amount calculated', orderAmount);

          if (minimumOrderAmount && minimumOrderAmount > 0 && orderAmount < minimumOrderAmount) {
            Log.debug('Order amount is less than minimum order amount', { minimumOrderAmount, orderAmount });
            toast.error(`The minimum order amount for delivery is ${minimumOrderAmount}`);
            return;
          }

          if (freeDeliveryAmountLimit && freeDeliveryAmountLimit > 0 && orderAmount >= freeDeliveryAmountLimit) {
            Log.debug('Order amount exceeds free delivery cost limit', { freeDeliveryAmountLimit, orderAmount });
            order.deliveryFee = 0;
          } else {
            Log.debug('Setting delivery fee', deliveryFee);
            order.deliveryFee = deliveryFee ?? 0;
          }
        } else {
          Log.debug('No delivery settings found');
        }

        setActiveStep(1);
      }
    },
  });

  const saveDeliveryInfo = (values: any) => {
    localStorage.setItem(DELIVERY_INFO_STORAGE_KEY, JSON.stringify(values));
  };

  const loadDeliveryInfo = () => {
    const savedInfo = localStorage.getItem(DELIVERY_INFO_STORAGE_KEY);
    return savedInfo ? JSON.parse(savedInfo) : null;
  };

  useEffect(() => {
    const savedInfo = loadDeliveryInfo();
    if (savedInfo) {
      formik.setValues(savedInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    saveDeliveryInfo(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  Log.debug("Checkout.tsx rendering");

  const initializePayment = useCallback(async () => {
    order.discountPercentage = DiscountUtil.getDiscount(customer);
    const { total, discountString } = OrderUtil.getOrderAmount(order, currency);

    if (total > 0 && initPaymentOnce.current && !payInPerson) {
      try {
        initPaymentOnce.current = false;

        if (order.discountPercentage > 0) {
          setComebackMessage(`Congratulations! You received a ${discountString} discount for being a returning customer. Pay again within the next 1 to 60 days to receive a ${order.discountPercentage}% discount again.`);
        } else {
          if (customer.businessSettings.discountPercentage && customer.businessSettings.discountPercentage > 0)
            setComebackMessage(`Pay again within the next 60 days (but after 24 hours) to receive a ${customer.businessSettings.discountPercentage}% discount.`);
        }

        const clientSecret = await StripeUtil.createPaymentIntentConnect(total, customer);
        setClientSecret(clientSecret);
      } catch (error: any) {
        const errorMessage = error?.message.includes("The amount must be greater than or equal to the minimum charge")
          ? 'The total cost is too low to be processed'
          : 'Failed to initialize payment';
        toast.error(errorMessage);
        Log.error('Payment initialization failed', error);
        throw new AppError('Failed to initialize payment', error);
      }
    }
  }, [currency, customer, order, payInPerson]);

  useEffect(() => {
    initializePayment();
  }, [initializePayment]);

  if (!clientSecret) return null;

  const stripePromise = loadStripe(Config.getStripePublicKey(), {
    stripeAccount: customer.businessSettings.connectedAccountId,
  });

  const handlePaymentSuccess = (result: PaymentIntentResult) => {
    const deliveryDetails: DeliveryOrderDetails = {
      fullName: formik.values.fullName,
      phoneNumber: formik.values.phoneNumber,
      deliveryInstructions: formik.values.deliveryInstructions,
      address: formik.values.address,
      deliveryFee: 0,
    };

    order.deliveryDetails = deliveryDetails;

    onPaymentSuccess(result);

    if (order.discountPercentage && order.discountPercentage > 0) {
      DiscountUtil.saveDiscount(customer.id);
    }
  };

  const handleTipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customer.businessSettings.tipsPercentage)
      order.tipPercentage = event.target.checked ? customer.businessSettings.tipsPercentage : undefined;
  };

  const handleInPersonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      order.paymentStatus = PaymentStatus.PAY_IN_PERSON;
      setPayInPerson(true);
    } else {
      order.paymentStatus = PaymentStatus.NOT_PAID;
      setPayInPerson(false);
    }
  };


  const handleNextButtonClick = async () => {
    const errors = await formik.validateForm();
    console.log('Validation Errors:', errors); // Log validation errors

    if (Object.keys(errors).length === 0) {
      await formik.submitForm();
    } else {
      toast.error('Please fill in all required fields');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText("5555 5555 5555 4444");
    toast.success("Card number copied to clipboard!");
  };

  const getDeliveryMessage = (): string => {
    const { delivery } = customer.businessSettings;
    const totalAmount = OrderUtil.getOrderAmount(order, currency).total;

    if (
      (delivery?.freeDeliveryAmountLimit && delivery.freeDeliveryAmountLimit > 0 && totalAmount >= delivery.freeDeliveryAmountLimit) || // there is a limit for free delivery and the order amount is greater than that
      (!delivery?.deliveryFee || delivery.deliveryFee <= 0) // no delivery fee defined
    ) {
      return 'Good news! Your order qualifies for free delivery.';
    }

    const deliveryFeeMessage = `Delivery fee: ${OrderUtil.formatAmount(delivery.deliveryFee, currency)}.`; // show delivery fee

    let freeDeliveryMessage = '';
    if (delivery.freeDeliveryAmountLimit && delivery.freeDeliveryAmountLimit > 0) {
      const amountToFreeDelivery = delivery.freeDeliveryAmountLimit - totalAmount;
      freeDeliveryMessage = ` Spend ${OrderUtil.formatAmount(amountToFreeDelivery, currency)} more to get free delivery.`;
    }

    return deliveryFeeMessage + freeDeliveryMessage;
  };


  return (
    <Container maxWidth="sm" sx={{ my: 2 }}>
      {qrCode.locationType === QrCodeLocationType.ADDRESS && activeStep === 0 && (
        <Card>
          <CardContent>
            <Typography variant="h6" align="center" sx={{ mb: 1 }}>
              Delivery Information
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mb: 2 }}>
              {getDeliveryMessage()}
            </Typography>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <AddressSelection
                  countryCode={customer.businessSettings.countryCode}
                  fieldName="address"
                  label="Delivery Address"
                  defaultPlaceId={formik.values.address.placeId}
                  setFieldValue={formik.setFieldValue}
                  // Removed setValidationError prop
                />
                {/* Display only the first error message for 'address' */}
                {formik.touched.address && formik.errors.address && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {getFirstError(formik.errors.address)}
                  </Typography>
                )}
                <TextField
                  fullWidth
                  margin="normal"
                  label="Full Name"
                  {...formik.getFieldProps('fullName')}
                  error={Boolean(formik.touched.fullName && formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName ? String(formik.errors.fullName) : ""}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Phone Number"
                  {...formik.getFieldProps('phoneNumber')}
                  error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber ? String(formik.errors.phoneNumber) : ""}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Delivery Instruction (Optional) e.g. floor number"
                  {...formik.getFieldProps('deliveryInstructions')}
                  error={Boolean(formik.touched.deliveryInstructions && formik.errors.deliveryInstructions)}
                  helperText={formik.touched.deliveryInstructions && formik.errors.deliveryInstructions ? String(formik.errors.deliveryInstructions) : ""}
                />
                <button type="submit" style={{ display: 'none' }}>Submit</button>
              </form>
            </FormikProvider>
          </CardContent>
        </Card>
      )}
      {activeStep === 1 && (
        <Box>
          {!payInPerson && customer.businessSettings.testMode && (
            <Alert severity="info" sx={{ mb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  Test Mode is enabled, no charges will be applied.<br />
                  Use this CVC number for testing: 5555 5555 5555 4444
                </Box>
                <IconButton onClick={copyToClipboard} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            </Alert>
          )}
          {comebackMessage && (
            <Alert severity="success" sx={{ mt: 1, mb: 1 }}>
              {comebackMessage}
            </Alert>
          )}
          <Card>
            <CardContent>
              <Typography
                variant="h6"
                align="center"
                sx={{ mb: (serviceFee > 0 || deliveryFee > 0) ? 0 : 2 }}
              >
                Total Amount: {totalString}
              </Typography>
              {(serviceFee > 0 || deliveryFee > 0) && (
                <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mb: 2 }}>
                  Included cost:
                  {serviceFee > 0 && ` Service Fee: ${serviceFeeString}`}
                  {serviceFee > 0 && deliveryFee > 0 && ','}
                  {deliveryFee > 0 && ` Delivery Fee: ${deliveryFeeString}`}
                </Typography>
              )}
              {!payInPerson && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm
                    clientSecret={clientSecret}
                    onPaymentSuccess={handlePaymentSuccess}
                    executePayment={executePayment}
                  />
                </Elements>
              )}
              {customer.businessSettings.tipsPercentage && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', my: 1, mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleTipChange}
                        color="primary"
                      />
                    }
                    label={`Add ${customer.businessSettings.tipsPercentage}% Tip`}
                  />
                </Box>
              )}
              {customer.businessSettings.payInPerson && qrCode.locationType !== QrCodeLocationType.ADDRESS && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', my: 1, mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={payInPerson}
                        onChange={handleInPersonChange}
                        color="primary"
                      />
                    }
                    label="Pay later in person e.g. cash"
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
      {activeStep === 0 && qrCode.locationType === QrCodeLocationType.ADDRESS ? (
        <StandardButton
          text={"Next"}
          action={handleNextButtonClick}
          style={{ width: '80%', height: '56px', position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}
        />
      ) : (
        <StandardButton
          text={"Submit Order"}
          action={handleSubmitOrderClick}
          style={{ width: '80%', height: '56px', position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}
        />
      )}
    </Container>
  );
};
