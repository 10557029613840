import { createContext, useContext, useState, useEffect, ReactNode, FC, useMemo } from 'react';
import { Typography, Container, CircularProgress, Backdrop } from '@mui/material';
import { BasketData } from './BasketData';
import { useTranslation } from 'react-i18next';
import { AppError, CallerType, Customer, Log, ProductFilter, ProductUtil, QrCode, QrCodeLocationType } from 'base.f6st.com';
import { CustomerClient, LoginClient } from 'common.f6st.com';

type AppContextType = {
    qrCode: QrCode;
    productFilter: ProductFilter | undefined;
    basket: BasketData;
    setProductFilter: (filter: ProductFilter | undefined) => void;
    resetProductFilter: () => void;
    firstAppOpening: boolean;
    setFirstAppOpening: (value: boolean) => void;
    recommendationShown: boolean;
    setRecommendationShown: (value: boolean) => void;
    getCustomer: () => Customer;
    loadLanguage: (customer: Customer, language: string) => Promise<void>;
    isLoggedInUser: () => boolean; // New function to check if user is logged in
};

const AppContext = createContext<AppContextType | undefined>(undefined);
let loadCustomerOnce: boolean = true;

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};

type AppProviderProps = {
    children: ReactNode;
    qrCodeId: string;
    customerId: string;
    locationType: QrCodeLocationType;
};

export const AppProvider: FC<AppProviderProps> = ({ children, qrCodeId, customerId, locationType }) => {
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [productFilter, setProductFilter] = useState<ProductFilter | undefined>(undefined);
    const [validQCodeProvided, setValidQCodeProvided] = useState<boolean>(true);
    const [firstAppOpening, setFirstAppOpening] = useState<boolean>(false);
    const [recommendationShown, setRecommendationShown] = useState<boolean>(false);
    const [basket, setBasket] = useState<BasketData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const { i18n } = useTranslation();

    const resetProductFilter = () => {
        setProductFilter(undefined);
    };

    const loadLanguage = async (customer: Customer, language: string): Promise<void> => {
        Log.debug("Load new language", { language });

        if (!i18n.hasResourceBundle(language, 'translation')) {
            const translations = await import(`../i18n/${language}.json`);
            Log.debug("Loading additional i18n language at startup", { language });
            i18n.addResourceBundle(language, 'translation', translations.default, true, true);
        }

        if (!ProductUtil.isProductsTexts(customer, language)) {
            const languageCodePrimary = customer.businessSettings.languageCodePrimary;
            Log.debug("Loading additional language at startup", { language, languageCodePrimary });
            const products = languageCodePrimary === language
                ? await CustomerClient.getProductsTexts(CallerType.ORDER, customer.id, languageCodePrimary) // no translation
                : await CustomerClient.getProductsTexts(CallerType.ORDER, customer.id, languageCodePrimary, language); // might be translated

            ProductUtil.setProductsTexts(customer, language, products);
        }
    };

    const qrCode = useMemo(() => ({
        id: qrCodeId,
        customerId: customerId,
        locationType: locationType
    }), [qrCodeId, customerId, locationType]);

    // Function to determine if the user is logged in
    const isLoggedInUser = () => {
        return !!customer;
    };

    useEffect(() => {
        if (!loadCustomerOnce) return;
        loadCustomerOnce = false;

        Log.debug('Loading customer data');

        const loadCustomerData = async () => {
            if (!qrCodeId) {
                Log.debug('No QR code provided');
                setLoading(false);
                return;
            }
            try {
                const languageCode = i18n.language;
                Log.debug("Currently detected i18n language", languageCode);
                const customer = await LoginClient.loginOrder(qrCode, languageCode);
                if (!customer) {
                    throw new AppError('Customer not found');
                }
                Log.debug("Customer data loaded", customer);
                setBasket(new BasketData(customer, qrCode));
                await loadLanguage(customer, languageCode);
                setCustomer(customer);
            } catch (error) {
                Log.error('Failed to load customer data', error);
                setValidQCodeProvided(false);
            } finally {
                setLoading(false);
            }
        };

        loadCustomerData();
    }, [qrCodeId]);

    useEffect(() => {
        if (customer) setValidQCodeProvided(true);
    }, [customer]);

    if (!validQCodeProvided) {
        return (
            <Container>
                <Typography variant="h4" align="center" sx={{ mt: 4 }}>
                    Access to F6ST requires a valid QR code
                </Typography>
            </Container>
        );
    }

    const getCustomer = (): Customer => {
        if (!customer) {
            throw new AppError('Customer is not available');
        }
        return customer;
    };

    return (
        <AppContext.Provider value={{
            qrCode,
            getCustomer,
            productFilter,
            basket: basket!,
            setProductFilter,
            resetProductFilter,
            firstAppOpening,
            setFirstAppOpening,
            recommendationShown,
            setRecommendationShown,
            loadLanguage,
            isLoggedInUser,  // Provide isLoggedInUser function in context
        }}>
            {loading ? (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                children
            )}
        </AppContext.Provider>
    );
};
