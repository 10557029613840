import CustomAppBar from "../components/CustomAppBar";
import { useEffect, useState, useRef, useMemo } from "react";
import { Container, Grid2, Alert } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAppContext } from '../components/AppProvider';
import { PaymentIntentResult } from "@stripe/stripe-js";
import { OrderStorage } from "../components/OrderStorage";
import { Checkout } from "../components/Checkout";
import { ProductRecommendation, ProductsTextsUtil, Log, OrderUtil, AppError, PaymentStatus, OrderItem, IDUtil, CallerType, I18nUtil } from "base.f6st.com";
import { StripeUtil, StandardButton, ProductAssistantClient, OrderClient, ProductCard, CardUseCase } from "common.f6st.com";
import { Link } from "react-router-dom";

export const BasketPage = () => {
  const navigate = useNavigate();
  const { basket, recommendationShown, setRecommendationShown } = useAppContext()!;
  const customer = useAppContext().getCustomer();
  const productFilter = useAppContext().productFilter;
  const { t, i18n } = useTranslation();
  const [startCheckout, setStartCheckout] = useState(false);
  const executePayment = useRef<() => void>(() => { });
  const [recommendation, setRecommendation] = useState<ProductRecommendation | null>(null);
  const productRecommendationEnabled = customer.businessSettings.productRecommendation;
  const products = customer.products;
  const txt = useMemo(() => new ProductsTextsUtil(customer, i18n.language), [customer, i18n.language]);

  const [basketChangeTrigger, setBasketChangeTrigger] = useState(false);

  useEffect(() => {
    if (basket.isEmpty()) {
      // Do the navigate call, but don't return it.
      navigate('/');
      return;
    }

    // Wrap your logic to avoid returning a Promise from useEffect directly.
    const maybeFetchRecommendation = async () => {
      if (productRecommendationEnabled && !recommendationShown && !recommendation) {
        await fetchRecommendation();
      } else {
        Log.debug("No product recommendation has been executed");
      }
    };
    maybeFetchRecommendation();

    // Return the cleanup function (if needed)
    return () => {
      setRecommendationShown(true); // Show the recommendation only once
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket, navigate, recommendationShown, basketChangeTrigger]);

  if (!customer.businessSettings || !customer.businessSettings.connectedAccountId) {
    navigate('/');
    return null;
  }

  const fetchRecommendation = async () => {
    try {
      const basketItemsString = OrderUtil.toStringForProductRecommendation(basket.getOrder().items, txt);
      const productsString = OrderUtil.toStringForProductRecommendation(products.categories, txt);
      const lastThreeOrdersString = OrderUtil.toStringForProductRecommendation(OrderStorage.getAll(customer.id, 3), txt);
      const recommendation = await ProductAssistantClient.getRecommendation(CallerType.ORDER,
        basketItemsString,
        i18n.language,
        productsString,
        productFilter,
        lastThreeOrdersString
      );
      Log.debug("Received product recommendation", recommendation);
      if (!recommendation) return;
      setRecommendation(recommendation);
      setRecommendationShown(true); // Don't show recommendation again, just once
    } catch (error) {
      Log.error(`Error getting recommendation: ${(error as Error).message}`);
    }
  };

  const onPaymentSuccess = async (result: PaymentIntentResult) => {
    if (!result.paymentIntent) throw new AppError('Payment intent not found');
    Log.debug('Payment successful', result);

    const paymentMethod = result.paymentIntent.payment_method;

    let paymentMethodId: string;
    if (typeof paymentMethod === 'string') {
      paymentMethodId = paymentMethod;
    } else if (paymentMethod && typeof paymentMethod === 'object' && paymentMethod.id) {
      paymentMethodId = paymentMethod.id;
    } else {
      throw new AppError('Invalid payment method');
    }

    try {
      const paymentMethodType = await StripeUtil.getPaymentMethodType(paymentMethodId, customer);
      const order = basket.finalize(txt, PaymentStatus.PAID, paymentMethodType);
      await OrderClient.putOrder(CallerType.ORDER, order);
      await OrderStorage.save(order, customer.id);

      toast.success('Thank you for your order');
      navigate('/orders');
    } catch (error) {
      Log.error('Failed to finalize order', error);
      toast.error('Failed to complete your order');
    }
  };


  const handleSubmitOrderClick = () => {
    if (basket.getOrder().paymentStatus === PaymentStatus.PAY_IN_PERSON) {
      const order = basket.finalize(txt, PaymentStatus.PAY_IN_PERSON, undefined);
      OrderClient.putOrder(CallerType.ORDER, order);
      OrderStorage.save(order, customer.id);
      toast.success(`Thank you for your order!`);
      navigate('/orders');
    } else if (executePayment.current) {
      executePayment.current();
    }
  };

  const handleBasketChange = (orderItem: OrderItem) => {
    basket.removeFromBasket(orderItem.id);
    setBasketChangeTrigger(!basketChangeTrigger);
  };

  const addRecommendedProductToBasket = (categoryId: string, productId: string) => {
    const product = products.categories.flatMap(category => category.products).find(p => p.id === productId);
    if (product) {
      const orderItem: OrderItem = {
        id: IDUtil.getShortId(),
        product,
        categoryId: categoryId,
        quantity: 1,
        recommended: true,
        vatPercentage: OrderUtil.getProductVATPercentage(product, customer),
      };
      basket.addItem(orderItem);
      toast.success(`${txt.get(product.id)} added to basket`);
      handleBasketChange(orderItem); // Ensure the change is reflected in the basket
    }
  };

  const startCheckoutProcess = () => {
    setStartCheckout(true);
  };

  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const { total, totalString } = OrderUtil.getOrderAmount(basket.getOrder(), currency);

  return (
    <>
      <CustomAppBar backButton />
      <Container>
        {!startCheckout ? (
          <>
            <Grid2 container spacing={2} columns={12} mt={2}>
              {basket.getOrder().items.map((orderItem: OrderItem) => (
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/product/${orderItem.product.id}`}
                  state={{ orderItem }}
                >
                  <ProductCard key={orderItem.id} product={orderItem.product} categoryId={orderItem.categoryId} orderItem={orderItem} useCase={CardUseCase.BASKETPAGE} onBasketChange={handleBasketChange} customer={customer} txt={txt} />
                </Link>
              ))}
            </Grid2>
            {recommendation && (
              <Alert
                action={
                  <StandardButton
                    text={`Add`}
                    action={() => addRecommendedProductToBasket(recommendation.categoryId, recommendation.productId)}
                    style={{ width: '80px', height: '45px', backgroundColor: '#e84b4bee' }}
                  />
                }
                severity="error"
                sx={{ mt: 2 }}
              >
                {recommendation.message}
              </Alert>
            )}
            <StandardButton
              text={`${t('buttons.pay')} ${totalString}`}
              action={startCheckoutProcess}
              disabled={total <= 0}
              style={{ width: '80%', height: '56px', position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}
            />
          </>
        ) : (
          <Checkout
            onPaymentSuccess={onPaymentSuccess}
            executePayment={(callback: () => void) => { executePayment.current = callback; }}
            handleSubmitOrderClick={handleSubmitOrderClick}
          />
        )}
      </Container>
    </>
  );
};
